import React from "react";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { authSetPasswordAPI } from "../../../../services/auth.service";
import { useUserContext } from "../../../../context/UserContext";
import toast from "react-hot-toast";
import { toastErrorDisplay } from "../../../../helper/toastErrorDisplay";
import MainImage from "../../../../_metronic/assets/images/common/main.png";
import MainLogo from "../../../../_metronic/assets/images/common/main_logo.png";
const SetPassword = () => {
  const { user } = useUserContext();
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  // const {saveAuth, setCurrentUser} = useAuth()
  const [showPasswordChecked1, setShowPasswordChecked1] = useState(false);
  const [showPasswordChecked2, setShowPasswordChecked2] = useState(false);

  const navigation = useNavigate();
  const { id, token, code } = useParams();

  useEffect(() => {
    if (user) {
      navigation("/app");
    }
  }, [user]);

  const setPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    confirm_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: setPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const res = await authSetPasswordAPI(id, token, code, {
          password: values.password,
          confirm_password: values.confirm_password,
        });
        toast.success(res.message);
        navigation("/auth");
      } catch (error) {
        // setErrorMessage(error);
        toastErrorDisplay(error);
        setSubmitting(false);
      }
    },
  });

  const passwordShowChecked1 = (e) => {
    setShowPasswordChecked1(e.target.checked);
  };

  const passwordShowChecked2 = (e) => {
    setShowPasswordChecked2(e.target.checked);
  };

  return (
    <div className="auth-main-section">
      <div className="row">
        <div className="col-6 p-0">
          <img
            src={MainImage}
            alt="main image"
            className="img-fluid"
            style={{ height: "100vh" }}
          />
        </div>
        <div className="col-6">
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-8">
                <form
                  className="form w-100"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id="kt_login_signin_form"
                >
                  <>
                    <div className="text-center mb-45">
                      {/* logo from public folder */}
                      <img
                        src={MainLogo}
                        className="mb-3"
                        alt="Logo"
                        width="80"
                      />
                    </div>
                    <div className="mb-11">
                      <h1 className=" mb-3 text-nowrap font-primary-color font-55 font-weight-900">
                        Reset Password
                      </h1>
                      <h2 className="font-gray-color mb-3 font-18  ">
                        Please enter your new password
                      </h2>
                      {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                    </div>

                    {/* begin::Form group */}
                    <div className="fv-row mb-2">
                      <label className="form-label fs-6 fw-bolder text-dark">
                        Password
                      </label>
                      <input
                        placeholder="Password"
                        {...formik.getFieldProps("password")}
                        className={clsx(
                          "form-control bg-transparent",
                          {
                            "is-invalid":
                              formik.touched?.password &&
                              formik.errors?.password,
                          },
                          {
                            "is-valid":
                              formik.touched?.password &&
                              !formik.errors?.password,
                          }
                        )}
                        // type='password'
                        type={showPasswordChecked1 ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                      />
                      {formik.touched?.password && formik.errors?.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors?.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-check form-check-custom form-check-solid  mb-8">
                      <input
                        className="form-check-input"
                        style={{ border: "1px solid #ccc" }}
                        type="checkbox"
                        checked={showPasswordChecked1}
                        onChange={(e) => passwordShowChecked1(e)}
                        name="showPassword"
                        id="showPassword"
                      />
                      <label className="form-check-label" htmlFor="showPass">
                        Show Password
                      </label>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="fv-row mb-3">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Confirm Passowrd
                      </label>
                      <input
                        // type='password'
                        type={showPasswordChecked2 ? "text" : "password"}
                        placeholder="Confirm Password"
                        autoComplete="off"
                        {...formik.getFieldProps("confirm_password")}
                        className={clsx(
                          "form-control bg-transparent",
                          {
                            "is-invalid":
                              formik.touched.confirm_password &&
                              formik.errors.confirm_password,
                          },
                          {
                            "is-valid":
                              formik.touched.confirm_password &&
                              !formik.errors.confirm_password,
                          }
                        )}
                      />
                      {formik.touched.confirm_password &&
                        formik.errors.confirm_password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.confirm_password}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        style={{ border: "1px solid #ccc" }}
                        type="checkbox"
                        checked={showPasswordChecked2}
                        onChange={(e) => passwordShowChecked2(e)}
                        name="showPassword"
                        id="showPassword"
                      />
                      <label className="form-check-label" htmlFor="showPass">
                        Show Password
                      </label>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Wrapper */}
                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                      <div />

                      {/* begin::Link */}

                      {/* end::Link */}
                    </div>
                    {/* end::Wrapper */}

                    {/* begin::Action */}
                    <div className="d-grid mb-10">
                      <button
                        type="submit"
                        id="kt_sign_in_submit"
                        className="btn bg-primary text-white"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Continue</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Action */}
                  </>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    <Link to="/auth/login" className="link-primary">
                      Sign in
                    </Link>{" "}
                    | &nbsp;
                    <Link to="/" className="link-primary">
                      Home
                    </Link>
                  </div>
                </form>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
