/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { authLoginAPI } from "../../../../services/auth.service";
import { useUserContext } from "../../../../context/UserContext";
import toast from "react-hot-toast";
import OTPModal from "../../../../components/Modals/OTPModal";
import MainImage from "../../../../_metronic/assets/images/common/main.png";
import MainLogo from "../../../../_metronic/assets/images/common/main_logo.png";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
  device_code: localStorage.getItem("device_code") || "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showPasswordChecked, setShowPasswordChecked] = useState(false);
  const { user, setLogin, isLoading } = useUserContext();
  const [OTPShow, setOTPShow] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState("");
  const navigation = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      // try {
      //   // const { data: auth } = await login(values.email, values.password);
      //   const auth = await authLoginAPI({
      //     email: values.email,
      //     password: values.password,
      //     device_code: values.device_code,
      //   });
      //   // console.log(auth);

      //   // saveAuth(auth);
      //   // setCurrentUser(user);
      // } catch (error) {
      //   console.error(error);
      //   saveAuth(undefined);
      //   setStatus("The login details are incorrect");
      //   setSubmitting(false);
      //   setLoading(false);
      // }

      try {
        const res = await authLoginAPI({
          email: values.email,
          password: values.password,
          device_code: values.device_code,
        });
        if (res?.user) {
          const status = setLogin(res.token, res.user);
          if (!status) {
            toast.error("You are not authorize for this app");
          }
        } else {
          toast.success(res.message);
          setFirstTimeLogin(res.token);
          setOTPShow(true);
        }
      } catch (error) {
        console.log("error", error);
        if (error.errorTitle) {
          toast.error(error.errorTitle);
        }

        setSubmitting(false);
      } finally {
        setLoading(false);
      }
    },
  });

  const passwordShowChecked = (e) => {
    setShowPasswordChecked(e.target.checked);
  };
  const handleOTPClose = () => {
    setOTPShow(false);
  };

  useEffect(() => {
    if (user) {
      navigation("/app");
    }
  }, [user]);

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div className="auth-main-section">
        <div className="row">
          <div className="col-6 p-0">
            <img
              src={MainImage}
              alt="main image"
              className="img-fluid"
              style={{ height: "100vh" }}
            />
          </div>
          <div className="col-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <div>
                    <form
                      className="form w-100"
                      onSubmit={formik.handleSubmit}
                      noValidate
                      id="kt_login_signin_form"
                    >
                      {/* begin::Heading */}
                      <div className="text-center mb-45">
                        {/* logo from public folder */}
                        <img
                          src={MainLogo}
                          className="mb-3"
                          alt="Logo"
                          width="80"
                        />
                      </div>
                      <div className="text-center mb-11">
                        <h1 className=" mb-3 text-nowrap font-primary-color font-55 font-weight-900  ">
                          Welcome Back
                        </h1>
                        <h2 className="font-gray-color mb-3 font-18 text-nowrap ">
                          Please input your valid email and password
                        </h2>
                        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                      </div>

                      {formik.status ? (
                        <div className="mb-lg-15 alert alert-danger">
                          <div className="alert-text font-weight-bold">
                            {formik.status}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* begin::Form group */}
                      <div className="fv-row mb-8">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Email"
                          {...formik.getFieldProps("email")}
                          className={clsx(
                            "form-control bg-transparent",
                            {
                              "is-invalid":
                                formik.touched.email && formik.errors.email,
                            },
                            {
                              "is-valid":
                                formik.touched.email && !formik.errors.email,
                            }
                          )}
                          type="email"
                          name="email"
                          autoComplete="off"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="fv-plugins-message-container">
                            <span role="alert">{formik.errors.email}</span>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}

                      {/* begin::Form group */}
                      <div className="fv-row mb-3">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                          Password
                        </label>
                        <input
                          type={showPasswordChecked ? "text" : "password"}
                          autoComplete="off"
                          {...formik.getFieldProps("password")}
                          className={clsx(
                            "form-control bg-transparent",
                            {
                              "is-invalid":
                                formik.touched.password &&
                                formik.errors.password,
                            },
                            {
                              "is-valid":
                                formik.touched.password &&
                                !formik.errors.password,
                            }
                          )}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.password}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}

                      {/* begin::Wrapper */}
                      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            style={{ border: "1px solid #ccc" }}
                            type="checkbox"
                            checked={showPasswordChecked}
                            onChange={(e) => passwordShowChecked(e)}
                            name="showPassword"
                            id="showPassword"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showPassword"
                          >
                            Show Password
                          </label>
                        </div>
                        <div />
                        <div />

                        {/* begin::Link */}
                        <Link
                          to="/auth/forgot-password"
                          className="link-primary"
                        >
                          Forgot Password ?
                        </Link>
                        {/* end::Link */}
                      </div>
                      {/* end::Wrapper */}

                      {/* begin::Action */}
                      <div className="d-grid mb-10">
                        <button
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn bg-primary text-white"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">SIGN IN</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      {/* end::Action */}

                      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
                    </form>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <OTPModal
        showModal={OTPShow}
        handleModal={handleOTPClose}
        token={firstTimeLogin}
      />
    </>
  );
}
