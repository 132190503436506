import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const CompleteProcessModal = ({
  title = "Modal Header Message",
  BodyMessage = () => {
    return <span>Body Message</span>;
  },
  showModal,
  handleModal,
  successMessageText = "Yes",
  actionSuccess = () => {},
  actionClose = () => {},
  loading = false,
  size = "lg",
  className = "",
  centered = false,
}) => {
  return (
    <Modal
      className={className}
      show={showModal}
      onHide={handleModal}
      size={size}
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title className="font-18 font-weight-600">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <BodyMessage />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          //   className="bg-primary text-white"
          onClick={() => {
            actionClose();
            handleModal();
          }}
        >
          Close
        </Button>
        <Button
          //   variant="primary"
          className="bg-primary text-white"
          onClick={() => {
            actionSuccess();
            // handleModal();
          }}
        >
          {loading && (
            <Spinner
              className="spinner-border-sm me-2"
              animation="border"
              variant="dark"
            />
          )}
          {successMessageText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompleteProcessModal;
