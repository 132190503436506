import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import {
  authResendOtpAPI,
  authVerifyOtpAPI,
} from "../../services/auth.service";
import { useUserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { toastErrorDisplay } from "../../helper/toastErrorDisplay";
import { Spinner } from "react-bootstrap";
import OTPImage from "../../_metronic/assets/images/common/otp_img.png";

const OTPModal = ({ showModal, handleModal, token }) => {
  const { setDeviceCode, setLogin } = useUserContext();
  const [otp, setOtp] = useState("");
  const [saveToken, setSaveToken] = useState(null);
  const [loadingProcess, setLoadingProcess] = useState(false);

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await authVerifyOtpAPI(saveToken, otp);
      const status = setLogin(res.token, res.user);
      if (!status) {
        toast.error("You are not authorize for this app");
      }
      setDeviceCode(res.device_code);
      handleModal();
    } catch (error) {
      toast.error(error.errorTitle);
    }
  };
  const handleResendOTP = async (e) => {
    e.preventDefault();
    setLoadingProcess(true);
    try {
      const otp = await authResendOtpAPI(token);
      setSaveToken(otp.token);
      toast.success("New OTP sent on your email");
    } catch (error) {
      toastErrorDisplay(error);
    } finally {
      setLoadingProcess(false);
    }
  };

  useEffect(() => {
    if (token) {
      setSaveToken(token);
    }
  }, [token]);

  return (
    <Modal
      show={showModal}
      onHide={handleModal}
      size="lg"
      className="otp-modal-section"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-np-22">Enter OTP</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className=" px-3 pt-5">
          <div className="text-center">
            <img src={OTPImage} alt="otp image" />
            <h1 className="font-24 font-weight-600 mt-3 ">OTP Verification</h1>
            <p>Enter the OTP send on your email to verify</p>
          </div>
          <form onSubmit={handleOTPSubmit}>
            <div className="form-group">
              <OtpInput
                // inputType="number"
                containerStyle={{
                  justifyContent: "center",
                  fontSize: "3rem",
                  padding: "1rem",
                }}
                value={otp}
                onChange={setOtp}
                placeholder="------"
                isInputNum={true}
                shouldAutoFocus={true}
                numInputs={6}
                renderSeparator={<span> &nbsp;</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="text-center py-3">
              <button
                className="btn bg-primary text-white px-5 btn-sm"
                id="btn-submit3"
                onClick={(e) => handleOTPSubmit}
              >
                {" "}
                Verify
              </button>
            </div>
            <div className="text-end pb-3 ">
              <button
                onClick={handleResendOTP}
                className="btn btn-link "
                style={{
                  color: "#424242",
                  fontWeight: "bold",
                  // paddingBottom: "4px",
                  // lineHeight: "1",
                  padding: 0,
                  // textDecoration: "none",
                }}
                id="btn-submit4"
                disabled={loadingProcess}
              >
                {loadingProcess && (
                  <Spinner
                    className="spinner-border-sm me-2"
                    animation="border"
                    variant="dark"
                  />
                )}
                Resend OTP
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
