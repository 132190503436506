import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import { authResetPasswordAPI } from "../../../../services/auth.service";
import { toast } from "react-hot-toast";
import { useUserContext } from "../../../../context/UserContext";
import MainImage from "../../../../_metronic/assets/images/common/main.png";
import MainLogo from "../../../../_metronic/assets/images/common/main_logo.png";
const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const { user } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(undefined);
  const navigation = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setHasErrors(undefined);
      try {
        const res = await authResetPasswordAPI({
          email: values.email,
        });
        toast.success(res.message);
        setSubmitting(false);
        setHasErrors(false);
      } catch (error) {
        if (error?.errorDetail?.length > 0) {
          toast.error(error.errorDetail[0].message);
        } else if (error.errorTitle) {
          toast.error(error.errorTitle);
        }
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (user) {
      navigation("/app");
    }
  }, [user]);

  return (
    <>
      <div className="auth-main-section">
        <div className="row">
          <div className="col-6 p-0">
            <img
              src={MainImage}
              alt="main image"
              className="img-fluid"
              style={{ height: "100vh" }}
            />
          </div>
          <div className="col-6">
            <div className="d-flex flex-column justify-content-center h-100">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate
                    id="kt_login_password_reset_form"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="text-center mb-45">
                      {/* logo from public folder */}
                      <img
                        src={MainLogo}
                        className="mb-3"
                        alt="Logo"
                        width="80"
                      />
                    </div>
                    <div className="mb-11">
                      <h1 className=" mb-3 text-nowrap font-primary-color font-55 font-weight-900">
                        Forgot Password
                      </h1>
                      <h2 className="font-gray-color mb-3 font-18 ">
                        Enter your email to reset your password
                      </h2>
                      {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                    </div>

                    {/* begin::Title */}
                    {hasErrors === true && (
                      <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">
                          Sorry, looks like there are some errors detected,
                          please try again.
                        </div>
                      </div>
                    )}

                    {hasErrors === false && (
                      <div className="mb-10 bg-light-info p-8 rounded">
                        <div className="text-info">
                          Sent password reset. Please check your email
                        </div>
                      </div>
                    )}
                    {/* end::Title */}

                    {/* begin::Form group */}
                    <div className="fv-row mb-8">
                      <label className="form-label fw-bolder text-gray-900 fs-6">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder=""
                        autoComplete="off"
                        {...formik.getFieldProps("email")}
                        className={clsx(
                          "form-control bg-transparent",
                          {
                            "is-invalid":
                              formik.touched.email && formik.errors.email,
                          },
                          {
                            "is-valid":
                              formik.touched.email && !formik.errors.email,
                          }
                        )}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      <button
                        type="submit"
                        id="kt_password_reset_submit"
                        className="btn bg-primary text-white me-4"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        <span className="indicator-label">SUBMIT</span>
                        {loading && (
                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      <Link to="/auth/login">
                        <button
                          type="button"
                          id="kt_login_password_reset_form_cancel_button"
                          className="btn  secondary-btn"
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          CANCEL
                        </button>
                      </Link>{" "}
                    </div>
                    {/* end::Form group */}
                  </form>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
