import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_API_URL;

const http = axios.create({ baseURL: `${baseURL}` });

function getAuthHeader() {
  let authHeader = { "Content-Type": "application/json" };
  const token = Cookies.get("token");
  if (token) {
    authHeader = { Authorization: `Bearer ${token}` };
  }
  return authHeader;
}

function get(url, headers = {}, params = {}) {
  return http.get(url, {
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function post(url, data, headers = {}, params = {}) {
  return http.post(url, data, {
    ...params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function put(url, data, headers = {}) {
  return http.put(url, data, {
    headers: { ...getAuthHeader(), ...headers },
  });
}

function remove(url, data, headers = {}, params = {}) {
  return http.delete(
    url,
    {
      ...params,
      headers: { ...getAuthHeader(), ...headers },
    },
    data
  );
}

export default { get, post, put, remove };
