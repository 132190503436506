/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";
import { useUserContext } from "../../../../context/UserContext";
import CompleteProcessModal from "../../../../components/Modals/CompleteProcessModal";

const HeaderUserMenu = () => {
  // const {currentUser, logout} = useAuth()
  const { user, logout } = useUserContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [allDeviceCheck, setAllDeviceCheck] = useState(false);
  const navigate = useNavigate();
  const logoutHandler = async () => {
    setShowLogoutModal(true);
  };

  const submitLogoutHandler = async () => {
    await logout(allDeviceCheck);
    setAllDeviceCheck(false);
    navigate("/", { replace: true });
    setShowLogoutModal(false);
  };
  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };
  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
      >
        {/* <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div> */}

        <div className="menu-item px-5 my-1">
          <Link to="/crafted/account/settings" className="menu-link px-5">
            Account Settings
          </Link>
        </div>

        <div className="menu-item px-5">
          <a onClick={logoutHandler} className="menu-link px-5">
            Sign Out
          </a>
        </div>
      </div>
      <CompleteProcessModal
        size="sm"
        title={`Logout`}
        successMessageText="Logout"
        BodyMessage={() => {
          return (
            <div>
              <p>Are you sure you want to Logout?</p>

              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => {
                    // console.log(e.target.checked);
                    setAllDeviceCheck(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label font-16 font-weight-600"
                  for="flexCheckDefault"
                >
                  Logout from all device.
                </label>
              </div>
            </div>
          );
        }}
        showModal={showLogoutModal}
        handleModal={handleCloseLogoutModal}
        className="logout-header-modal"
        // loading={loadingProcessPassword}
        actionSuccess={async () => {
          submitLogoutHandler();
        }}
      />
    </>
  );
};

export { HeaderUserMenu };
