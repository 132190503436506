import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const UserManagement = lazy(() => import("../pages/user/UserManagement"));
  const ShutterCalculator = lazy(() =>
    import("../pages/shutterCalculator/ShutterCalculator")
  );
  const InvoiceManagement = lazy(() =>
    import("../pages/invoice/InvoiceManagement")
  );
  const InventoryManagement = lazy(() =>
    import("../pages/inventory/InventoryManagement")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/apps/shutter-management/calculator" />} />
        {/* Pages */}
        {/* <Route path="dashboard" element={<DashboardWrapper />} /> */}
        <Route path="dashboard" 
        // redirect to the calculator page
        element={<Navigate to="apps/shutter-management/calculator" />} />
        <Route
          path="apps/shutter-management/calculator"
          element={<ShutterCalculator />}
        />
        <Route
          path="apps/shutter-management/invoice"
          element={<InvoiceManagement />}
        />
        <Route
          path="apps/user-management/users"
          element={<UserManagement />}
        />
        <Route path="apps/inventory" element={<InventoryManagement />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
