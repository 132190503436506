import httpService from "./http.service";

export const userMeAPI = () =>
  httpService
    .get(`/user/me`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const userUpdateAPI = (payload) =>
  httpService
    .put(`/user/update`, payload)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const userPasswordUpdateAPI = (payload) =>
  httpService
    .put(`/user/update-password`, payload)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));
