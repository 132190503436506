import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { userMeAPI } from "../services/user.service";
import {
  authLogoutAPI,
  authLogoutAllDeviceAPI,
} from "../services/auth.service";
import toast from "react-hot-toast";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkLogin = async () => {
    try {
      const userCookie = Cookies.get("token");
      if (userCookie) {
        const response = await userMeAPI();
        setUser(response.user);
      }
    } catch (err) {
      if (err.errorStatus === 404 && err.errorTitle === "Invalid Auth Token.") {
        setUser(null);
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const setDeviceCode = (code) => {
    localStorage.setItem("device_code", code);
  };

  const logout = async (fromAllDevice = false) => {
    try {
      if (fromAllDevice) {
        await authLogoutAllDeviceAPI();
      } else {
        await authLogoutAPI();
      }

      Cookies.remove("token");
      toast.success("Logout Successfully");
    } catch (error) {
      Cookies.remove("token");
    } finally {
      setUser(null);
    }
  };

  const setLogin = (token, user) => {
    if (user?.role === "ADMIN" || user?.role === "USER") {
      setUser(user);
      Cookies.set("token", token, {
        expires: 7,
        sameSite: "strict",
      });
      return true;
    } else {
      return false;
    }
  };

  const getToken = () => Cookies.get("token");

  const shareValue = {
    user,
    setUser,

    isLoading,
    logout,

    setDeviceCode,
    setLogin,

    getToken,
  };

  //
  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <UserContext.Provider value={shareValue}>{children}</UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
