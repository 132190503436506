import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserContext } from "../../context/UserContext";
import { Toaster } from "react-hot-toast";
// import Home from '../website/home'

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  const { currentUser } = useAuth();
  const { user, setLogin } = useUserContext();
  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="logout" element={<Logout />} />
            {/* <Route path='/' element={<Home />} /> */}
            {user ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                <Route index element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="/auth/*" element={<AuthPage />} />
                <Route path="/*" element={<Navigate to="/auth" />} />
              </>
            )}
          </Route>
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-left" autoClose={5000} />
    </>
  );
};

export { AppRoutes };
