import httpService from "./http.service";

export const authLoginAPI = (data) =>
  httpService
    .post("/auth/login", data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authLogoutAPI = () =>
  httpService
    .remove("/auth/logout")
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authLogoutAllDeviceAPI = () =>
  httpService
    .remove("/auth/logout-all-device")
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authRegisterAPI = (data) =>
  httpService
    .post("/auth/register", data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authVerifyOtpAPI = (token, code) =>
  httpService
    .get(`/auth/verify-otp/${token}/${code}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authResendOtpAPI = (token) =>
  httpService
    .get(`/auth/resend-otp/${token}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authResetPasswordAPI = (data) =>
  httpService
    .post(`/auth/reset-password`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const authSetPasswordAPI = (id, token, code, data) =>
  httpService
    .post(`/auth/set-password/${id}/${token}/${code}`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));
